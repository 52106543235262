:root {
	--control-background-color: #f4f9f0;
	--control-border-color: #88c656;
	--border-color-gray: #d5d5d5;
}

html {
	height: 100%;
}

body {
	font-family: sans-serif, 'Poppins';
	font-size: 1.2rem !important;
	line-height: 1.42857143;
	color: #333;
	height: 100%;
	overflow: hidden;
}

#app {
	overflow: hidden;
	height: 100%;
}

.invalid-container {
	background-color: #f0f0f0;
	height: 100%;
}

.invalid-header {
	background-color: #0973ba;
	height: 125px;
	padding: 6px;
}

.error-page {
	height: 100%;
}

.error-page-container {
	border-radius: 6px;
	height: 100%;
	background-color: #fff;
	z-index: 2;
	cursor: pointer;
	padding-top: 50%;
}

.error-page-container .message {
	font-size: 3em;
	text-align: center;
}

#awesome-pdf-viewer > .main {
	height: calc(90vh - 140px) !important;
}

#awesome-pdf-viewer > .main > .right-panel {
	position: relative;
}

.signing-page-main-container,
.pdf-preview-container,
#esign-aweosme-pdf-viewer {
	height: 100%;
}

#esign-aweosme-pdf-viewer > .main,
#esign-aweosme-pdf-viewer > .main > .side-panel {
	height: calc(100% - 45px) !important;
}

.view-layout > .main > .side-panel {
	z-index: 9995 !important;
}

.view-layout > .main > .page-viewer {
	z-index: 1;
}

.read-mode-toolbar {
	width: 355px !important;
}

.steps-wrapper {
	height: calc(100% - 60px);
}

.steps-body.without-footer {
	height: calc(100% - 55px) !important;
}

.header-container {
	background-color: #0973ba;
	background-color: var(--headerBgColor);
	height: 60px;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
}

.header-nav {
	margin: auto;
	width: 85%;
	text-align: center;
	color: white;
	display: flex;
	flex-wrap: wrap;
}

.header-nav > .title {
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.header-nav > .company-logo {
	max-height: 50px;
	margin: auto;
}

.page-container {
	height: calc(100vh - 135px);
	padding: 20px;
}

.page-parts-container {
	border: 2px solid #909090;
	border-radius: 5px;
	margin: auto;
	text-align: center;
	border-width: 3px;
	font-size: 15px;
}

.steps-body {
	border: none !important;
}

.step-layout {
	height: 100%;
}

.step-layout-left-panel-header,
.step-layout-body-header {
	font-size: 20px;
	padding: 0px 0px 5px 0px;
	font-weight: bolder;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.step-layout-body-header > .sub-title {
	font-weight: bold;
	font-size: 14px;
	height: 100px;
	color: #747474;
}

.step-layout-left-panel-body {
	padding: 0px 15px 0px 0px;
}

.step-layout-body {
	padding: 8px 8px 8px 8px !important;
}

.step-layout-left-panel-body,
.step-layout-body {
	height: calc(100%);
	overflow-y: auto;
	overflow-x: hidden;
}

.step-layout-body.without-header {
	height: 100%;
}

.step-layout-body {
	width: 100%;
	border: 2px solid #909090;
	border-radius: 5px;
	margin: auto;
}

.step-layout-content {
	min-height: 400px;
	height: 100%;
	border: none !important;
}

.step-layout-header + .step-layout-content {
	height: calc(100% - 50px);
}

.step-layout-body-container {
	margin: 0px auto 0px auto;
	padding: 8px !important;
	border: none !important;
}

.step-layout-left-panel {
	background-color: white;
}

.step-layout-left-panel,
.step-layout-body-container {
	border: 1px solid #eeeeee;
	border-radius: 2px;
	height: 100%;
}

.step-layout-body.white {
	background-color: white;
}

.step-layout-body.transparent {
	background-color: transparent;
}

.sign-method-container {
	width: 100%;
	height: 97%;
	background-color: white;
	margin: 0.5rem;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.sign-method-content > h5:nth-child(1) {
	margin-top: 2rem;
	font-weight: bold;
	font-size: 1.4rem;
}

.sign-method-content > h5:nth-child(2) {
	margin: 1rem 0;
	font-weight: bold;
	font-size: 1.4rem;
}

.sign {
	width: 20rem;
	margin-bottom: 1rem;
}

.step-grey {
	background-color: #f0f0f0;
}

#awesome-multi-steps-btn-previous {
	background-color: white !important;
	border-color: #0973ba !important;
	color: #0973ba !important;
}

#delegatee {
	font-size: 0.8rem !important;
	font-weight: bold;
}

.delegatee-modal-title {
	font-size: 0.9rem;
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.row {
	flex-wrap: nowrap !important;
}

.chip-input {
	width: 100%;
}

.dropdown-toggle.btn.btn-success {
	color: var(--control-border-color);
	background-color: var(--control-background-color);
	border-color: var(--control-border-color);
	border-radius: 4px !important;
	width: 100px;
	text-align: left;
}

.dropdown-toggle.btn.btn-success::after {
	position: absolute;
	right: 15px;
	top: 18px;
}

.YearMonthDay.col {
	display: inline-flex;
}

.select-control {
	margin: auto;
}

.select-control > .dropdown {
	width: max-content;
}

.scrollable-menu {
	height: auto;
	max-height: 400px;
	overflow-x: hidden;
	display: inline !important;
	font-size: 25px !important;
}

/*Custom Checkbox START*/
/* Customize the label (the container) */
.consent-checkbox {
	display: block;
	position: relative;
	padding: 10px 0px 0px 50px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.consent-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 16px;
	width: 16px;
	top: 20px;
	left: 20px;
	z-index: 99;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 15px;
	left: 15px;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border: 1px solid var(--control-border-color);
	border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.consent-checkbox input:hover ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.consent-checkbox input:checked ~ .checkmark {
	background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.consent-checkbox input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.consent-checkbox .checkmark:after {
	left: 5px;
	top: 2px;
	width: 7px;
	height: 11px;
	border: solid #9fe350;
	border: solid var(--control-border-color);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
/*Custom Checkbox END*/

.custom-input {
	color: var(--control-border-color);
	background-color: var(--control-background-color);
	border-color: var(--control-border-color);
	border-radius: 5px !important;
	width: 100%;
	width: 100%;
	border-width: 2px;
	height: 40px;
	font-size: 20px;
}

#controlled-tab-example-tabpane-home {
	height: 100%;
	background-color: transparent !important;
}

#controlled-tab-example-tabpane-profile {
	height: 100%;
	background-color: white !important;
}

.payment-refund-container {
	height: 100%;
	margin-right: 0px !important;
	padding-right: 0px !important;
	background-color: white;
}

.payment-refund-container .title,
.estimated-voucher-container .title,
.tax-payment-container .title,
.payment-instruction-container .title {
	margin-bottom: 5px;
	margin-left: 0px;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.89;
	letter-spacing: normal;
	color: #000000;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
	padding: 0px 10px;
	overflow: auto;
	height: 100%;
}

.section-title {
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #0973ba;
	clear: both;
}

.preSelect {
	clear: both;
	margin: 20px 1px;
}

.sub-section {
	clear: both;
}

.sub-section div {
	width: 50%;
	position: relative;
}

.sub-section .authority {
	font-size: 50px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
	color: #000000;
}

.sub-section .amount {
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.57;
	letter-spacing: normal;
}

.sub-section .ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	padding: inherit;
	font-size: 15px;
}

.total-amount-green {
	border-top: 1px solid #000;
	font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	width: 40% !important;
}

.currencytext {
	text-align: right;
	padding-right: 0px;
	/*font-family: monospace;*/
	font-family: sans-serif, 'Roboto', 'Poppins';
}

.flrt {
	float: right;
}

.orange {
	color: darkorange;
}

.green {
	color: #86bf51;
}

.red {
	color: #e00d0d;
}

.bold {
	color: #000;
}

.fllft {
	float: left;
}

.total-amount-red {
	border-top: 1px solid #000;
	font-size: 20px;
	font-weight: 700;
	width: 40% !important;
}

.nav-tabs {
	border-radius: 5px !important;
}

.nav-tabs .nav-link {
	border: 2px solid #8bc656 !important;
	font-size: 18px !important;
	flex: 1 1 auto;
	text-align: center;
	padding: 10px 5px 10px 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #0973ba !important;
	background-color: #86bf516e !important;
	font-size: 18px;
	border: 2px solid #86bf51 !important;
}

.kba-questions {
	font-weight: bold;
}

.form-check-input {
	width: 20px;
	height: 18px;
}

.form-check-label {
	margin-bottom: 10px !important;
	margin-left: 5px !important;
}

.sign-completed-body {
	text-align: center;
}

.sign-completed-icon {
	height: 85px;
}

.custom-button {
	background-color: #5ffc5f1c;
	border: 2px solid #83c786;
	height: 50px;
	border-radius: 5px;
	display: flex;
	padding: 8px;
	cursor: pointer;
}

.custom-button.left {
	float: left;
}

.custom-button.center {
	margin: auto;
}

.custom-button.xl {
	width: 285px;
}

.custom-button.lg {
	width: 225px;
}

.custom-button.md {
	width: 175px;
}

.custom-button.sm {
	width: 125px;
}

.custom-button.right {
	float: right;
}

.custom-button.right {
	float: right;
}

.custom-button:hover {
	background-color: #9fff9f6b;
}

.custom-button-icon-container {
	width: 25px;
}

.custom-button-text-container {
	overflow: hidden;
	user-select: none;
	color: #0274bb;
	font-weight: bold;
	font-size: 14px !important;
	margin: auto auto auto 10px;
	font-family: sans-serif, 'Roboto', 'Poppins';
}

#overlay-loader[data-show='true'] {
	display: block !important;
}

#overlay-loader {
	background: #ffffff;
	color: #666666;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9997;
	top: 0;
	left: 0;
	float: left;
	text-align: center;
	padding-top: 25%;
	opacity: 0.8;
}

#overlay-loader > .spinner {
	margin: 0 auto;
	height: 40px;
	width: 40px;
	animation: rotate 0.8s infinite linear;
	border: 3px solid #0973ba;
	/*border: 3px solid var(--headerBgColor);*/
	border-right-color: transparent;
	border-radius: 50%;
	top: 50%;
	left: 43%;
	position: fixed;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.warningModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9995;
	font-size: 1rem;
	background-color: rgba(217, 217, 217, 0.9);

	display: flex;
	justify-content: center;
	align-items: center;
}

.warningModal-content {
	max-width: 85%;
	padding: 1.2rem;
	text-align: center;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.warningModal-para1 {
	margin-bottom: 0.8rem;
}

.warningModal-para3 {
	margin: 0.8rem 0;
}

.warningModal-success {
	border-radius: 25px !important;
	color: white !important;
	border-color: #9fe350 !important;
	background-color: #89c05a !important;
	width: 10rem !important;
	border-style: none;
	padding: 0.5rem;
}

/* Bootstrap Modal Customization */

.modal-dialog {
	max-width: 900px !important;
	border-radius: 5px !important;
}

.modal-content {
	border: 3px solid #89c05a !important;
	border-radius: 5px !important;
}

.bootbox-accept {
	border-radius: 25px !important;
	color: white !important;
	border-color: #9fe350 !important;
	background-color: #89c05a !important;
	width: 115px !important;
}

.bootbox-cancel {
	background-color: white !important;
	border-color: #0973ba !important;
	color: #0973ba !important;
	border-radius: 25px !important;
	width: 115px !important;
}

.bootbox-title-icon {
	margin: 0px 10px 0px 0px;
}

.modal-footer {
	display: block !important;
	text-align: right !important;
}
/* End Of Bootstrap Modal Customization */

.button-bg {
	padding: 0px 20px 0px 20px;
	font-size: 20px !important;
	height: 50px;
	border-radius: 5rem !important;
	color: white !important;
	border-color: #9fe350 !important;
	background-color: #89c05a !important;
	font-weight: 600;
}

.button-bg[disabled] {
	opacity: 0.5;
}

.summary-begin-button {
	margin-top: 15px;
}

.summary-begin-button-container {
	text-align: center;
}

.step-layout-body > .tab-content {
	height: 78%;
}

.signature-control {
	font-size: 9px !important;
}

.kba-questions-list {
	height: calc(100% - 175px);
	overflow-y: auto;
	overflow-x: hidden;
}

.btn-primary {
	background-color: #88c656 !important;
	border: 1px solid #88c656;
	border-color: #88c656 !important;
}

.btn-primary,
.btn-light,
.btn-info {
	border-radius: 50px !important;
	min-width: 120px;
	font-weight: 500;
	font-size: 14px;
}

.btn-primary:hover {
	color: #fff;
	background-color: #88c656 !important;
	border-color: #88c656 !important;
}

.btn-secondary {
	min-width: 0 !important;
	border: 2px solid #8bc656 !important;
	background-color: #f3f9ee !important;
	color: black !important;
	min-width: 100px !important;
	border-radius: 5rem !important;
}

.btn-secondary:hover {
	background-color: #e8f4dd;
	border-color: #9fe350;
}

.btn-secondary:active {
	background-color: #e8f4dd !important;
	border-color: #9fe350 !important;
}

div#ddlCountryCode {
	width: 150px;
}

.phoneNumeberTextBox {
	height: 41px !important;
	border-radius: 5px !important;
}

.spouse-details-phone-number-container {
	display: inline-flex;
}

.bootbox > .modal-dialog {
	margin: 0.5rem !important;
}

/*.modal-dialog {
    margin: -50px auto 0px auto
}*/

.modal-title {
	width: 100%;
	float: right;
	margin-right: 10px;
}

.popover {
	border: 1px solid #0973ba;
	z-index: 9996;
	/*border: 1px solid var(--headerBgColor);*/
}

.popover-header {
	background-color: #0973ba;
	/*background-color: var(--headerBgColor) !important;*/
	color: white;
	/*color: var(--headerForeColor);*/
	font-size: 12px;
	font-weight: bold;
	margin: 1px;
}

.rct-node-icon {
	color: #717171;
}

li span.rct-text span.rct-node-clickable {
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	width: 200px !important;
}

.rct-node-clickable:focus {
	outline: 0;
	background: transparent;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
	display: none;
}

.react-checkbox-tree {
	display: block;
}

.header-left-container {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	width: auto;
}

.header-right-container {
	margin-left: auto;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	padding-right: 6%;
	min-width: 175px;
}

.header-account {
	margin-right: 15px;
}

.header-mydownload,
.header-contact-info,
.header-logout,
.header-account,
.header-taxYear,
.header-taxReturn {
	float: right;
	color: white;
	color: var(--headerForeColor);
	cursor: pointer;
	margin-left: 15px;
	padding: 12px 0px 0px 5px;
}

.header-contact-info a {
	color: white;
	color: var(--headerForeColor);
}

.header-contact-info a svg {
	max-width: 16px;
	max-height: 16px;
	margin-right: 5px;
}

.header-contact-info:hover,
.header-logout:hover {
	text-decoration: underline;
}

.header-taxYear a svg {
	max-width: 16px;
	max-height: 16px;
	margin-right: 5px;
}

.header-account a svg {
	max-width: 16px;
	max-height: 16px;
	margin-right: 5px;
}

.header-taxYear:hover {
	cursor: text;
}

.mail {
	font-size: 14px;
	color: #88c656;
	font-weight: bold;
}

.mail a:hover {
	cursor: pointer;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
	list-style: none;
	padding: 0 !important;
	margin: 0 !important;
}

.header-account a::after
/*, .header-taxYear a::after, .header-taxReturn a::after*/ {
	content: '';
	width: 0px;
	height: 1px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 7px solid #ffffff;
	position: absolute;
	margin-left: 5px;
	margin-top: 8px;
	z-index: 100;
}

#sidebarMenu {
	height: calc(100% - 60px);
	position: fixed;
	left: 0;
	width: 225px;
	margin-top: 60px;
	transform: translateX(-250px);
	transition: transform 250ms ease-in-out;
	/*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
	background-color: #0973ba;
	/*background-color: var(--headerBgColor);*/
	overflow: scroll;
	z-index: 9996 !important;
}

.sidebarMenuInner {
	margin: 0;
	padding: 0;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li {
	list-style: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	padding: 20px;
	cursor: pointer;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li span {
	display: block;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.5);
}

.sidebarMenuInner li a {
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}

input[type='checkbox']:checked ~ #sidebarMenu {
	transform: translateX(0);
}

#openSidebarMenu {
	transition: all 0.3s;
	box-sizing: border-box;
	display: none;
}

.sidebarIconToggle {
	transition: all 0.3s;
	box-sizing: border-box;
	cursor: pointer;
	position: absolute;
	z-index: 99;
	margin: 20px 0px 0px 0px;
	height: 22px;
	width: 22px;
}

.spinner {
	transition: all 0.3s;
	box-sizing: border-box;
	position: absolute;
	height: 3px;
	width: 100%;
	background-color: #fff;
}

.horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	margin-top: 3px;
}

.diagonal.part-1 {
	position: relative;
	transition: all 0.3s;
	box-sizing: border-box;
	float: left;
}

.diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	position: relative;
	float: left;
	margin-top: 3px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .horizontal {
	transition: all 0.3s;
	box-sizing: border-box;
	opacity: 0;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-1 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(135deg);
	margin-top: 8px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-2 {
	transition: all 0.3s;
	box-sizing: border-box;
	transform: rotate(-135deg);
	margin-top: -9px;
}

.show {
	display: block !important;
}

.sidebar {
	width: 225px !important;
	min-height: calc(100vh - 42px);
	user-select: none;
}

.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	font-size: 1.5rem !important;
}

.navbar-nav {
	float: left;
	margin: 0;
}

.navbar-nav > li {
	text-align: left;
	border-top: 1px solid #8e8e8e99;
	font-size: 15px;
	padding: 10px;
}

.navbar-nav > li:first-child {
	border-top: none;
}

.navbar-nav > li:first-child > h4 {
	border-top: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.navbar-nav > li:last-child {
	border-bottom: 1px solid #8e8e8e99;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
	position: relative;
}

.sidebar .nav-item .nav-link {
	display: block;
	text-align: left;
	padding: 1rem;
	width: 225px;
}

.sidebar .nav-item .nav-link {
	color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
	display: block;
}

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
	margin: 10px;
}

.sidebar .nav-item .nav-link span {
	font-size: 1.5rem;
	display: inline;
}

.nav-link span {
	color: #fff;
	text-shadow: none;
}

.layout-dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}

.layout-dropdown-menu {
	box-shadow: none;
	position: static !important;
	margin-left: 30px;
	top: 0;
	background-color: transparent;
	border: none;
}

.navbar-nav .layout-dropdown-menu {
	position: static;
	float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
	-webkit-transform: none !important;
	transform: none !important;
	left: calc(50px + 0.5rem) !important;
	margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #fff;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	font-size: 14px;
}

.layout-dropdown-menu.show {
	padding-top: 0px;
}

a.active.layout-dropdown-item {
	text-decoration: underline;
}

.myaccount-text.phonenumber {
	display: inline-flex;
}

.myaccount .modal-title {
	color: #0973ba;
	/*color: var(--headerBgColor);*/
	font-size: 14px;
	font-weight: bold;
}

.myaccount-text {
	padding: 10px;
	font-size: 12px;
}

.my-account-phonenumber {
	font-size: 15px;
	height: 38px !important;
}

.myaccount-text {
	padding: 10px;
	font-size: 12px;
}

.myaccount-changenumber {
	padding-right: 20px;
	padding-left: 4px;
}

.myaccount-countrycode {
	padding-right: 0px;
	padding-left: 20px;
}

.shepherd-footer .shepherd-button {
	font-size: 12px;
}

.modal {
	z-index: 9996;
}

.steps-wrapper > .steps-body > .step {
	animation-fill-mode: none !important;
}

/* { End }*/

/*welcome page CSS*/

.welcome-page-mobile {
	height: calc(100% - 70px);
	padding: 10px;
	overflow: auto;
}

.company-info-container {
	height: 45%;
	padding: 0px 10px 10px 10px;
}

.prepared-by-img {
	max-height: 100px;
	max-width: 100%;
}

.company-address-container {
	padding: 0px 0px 0px 0px;
}

.welcome-page-content-box {
	border: 1px solid #f3f3f3;
	border-radius: 5px;
	padding: 15px 15px;
	text-align: center;
	background-color: #fff;
	word-break: break-word;
	display: inline-grid;
	background-color: yellow;
	height: 65px;
}

.welcome-page-content-box.prepare-by {
	min-height: 250px !important;
}

.tax-year-header {
	font-size: 22px;
	font-weight: 600;
}

.page-parts-container1 {
	border: 2px solid #909090;
	border-radius: 5px;
	margin: auto;
	text-align: center;
	font-size: 15px;
	padding: 10px 10px 10px 10px;
}
/*welcome screen CSS Ends*/

/*OTP Page CSS*/
.otp-page {
	padding-top: 15px !important;
	overflow: auto;
	height: calc(100% - 70px);
}

.otp-page-container {
	border-radius: 6px;
	/*padding: 12% 10%;*/
	background-color: #fff;
	z-index: 2;
	cursor: pointer;
	border: 2px solid #909090;
	height: 50%;
	overflow: auto;
	padding-bottom: 10px;
}

.otp-page-container input {
	border: 2px solid #8bc656;
	border-radius: 5px;
	background-color: #f3f9ee;
	width: 130px;
	height: 40px;
	color: #000;
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
}

.otp-page-container input {
	width: 160px;
}

.otp-page-container input::-webkit-input-placeholder {
	font-weight: normal;
}

.otp-page-container input::-moz-placeholder {
	font-weight: normal;
}

.otp-page-container input:-ms-input-placeholder {
	font-weight: normal;
}

.otp-page-container input:-o-input-placeholder {
	font-weight: normal;
}

.otp-page-container input[type='radio'] {
	width: 70px;
	height: 20px;
}

.margin-top-50 {
	margin-top: 30px;
}

.btn-primary-accesscode {
	/*background-color: #88c656;*/
	/*background-color: var(--bottonBgColor) !important;*/
	border-radius: 50px;
	min-width: 150px;
	font-weight: 500;
	font-size: 16px;
	border: 2px solid #5a56c6;
	/*border: 1px solid var(--bottonBorderColor);*/
	width: 225px;
	height: 40px;
	color: #5a56c6;
	/*border-color: var(--bottonBorderColor) !important;*/
}

.access-code-expiry-info {
	font-style: italic;
}

/*OTP CSS End*/

/*SSN CSS Start*/

.ssn-page {
	padding-top: 15px !important;
	overflow: auto;
	height: calc(100% - 70px);
}

.ssn-page-container {
	border-radius: 6px;
	/*padding: 12% 10%;*/
	background-color: #fff;
	z-index: 2;
	cursor: pointer;
	height: 50%;
	border: 2px solid #909090;
}

.ssn-page-container input {
	border: 2px solid #8bc656;
	border-radius: 5px;
	background-color: #f3f9ee;
	width: 130px;
	height: 40px;
	color: #000;
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
}

.ssn-page-container-label-div {
	display: inline-block;
}

.ssn-page-container-input-div {
	display: inline-block;
	vertical-align: bottom;
}

.ErrorContainer {
	background-color: #f0f0f0;
	height: 100%;
}

.ErrorPageContent {
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.Errorheader {
	background-color: #0973ba;
	height: 52px;
	padding: 6px;
}

.MessageContainer {
	background: white;
	height: 60vh;
	width: 70vw;
	border-radius: 5px;
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ErrorMsgPara {
	font-size: 18px;
	text-align: center;
	padding-left: 20px;
	padding-right: 20px;
}

.mce-statusbar {
	display: none !important;
}

.btn.btn-primary.rounded i.fa.fa-save,
.btn.btn-primary.rounded i.fa.fa-times {
	padding-right: 5px;
}

.fa-times {
	margin-right: 3px;
}

.btn.btn-primary.rounded,
.btn.btn-secondary.rounded,
.btn.btn-default.rounded {
	min-width: 100px !important;
	border-radius: 5rem !important;
	border-color: rgb(159, 227, 80) !important;
}

.assign-delegatee-header {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.assign-delegatee-header-content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.auth-body-container {
	height: calc(80vh - 55px) !important;
	overflow-y: scroll !important;
}

.texttooltip {
	visibility: hidden;
	background-color: #ededed;
	color: black;
	text-align: center;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	bottom: -35px;
	right: -10px;
	padding: 5px 15px;
}

.custom-button-container:hover .texttooltip {
	visibility: visible;
}

/* overriding pdfViewer css */

.shepherd-content {
	width: 40px !important;
	height: 20px !important;
	line-height: 1 !important;
}

.shepherd-element-right button.shepherd-button:before {
	border: 10px solid transparent !important;
	border-right: 12px solid #ff9800 !important;
}

.shepherd-element-left button.shepherd-button:after {
	border: 10px solid transparent !important;
	border-left: 12px solid #ff9800 !important;
}

.awesome-pdf-viewer-radio-button + .awesome-pdf-viewer-radio-button-label {
	border: 1px solid #8bc34a !important;
}

.awesome-pdf-viewer-radio-button:checked + .awesome-pdf-viewer-radio-button-label:before {
	top: -30% !important;
}

.awesome-pdf-viewer-tooltip .tooltiptext {
	min-width: 65px;
	height: 20px;
	font-size: 12px;
}

.awesome-pdf-viewer-tooltip .tooltiptext::after {
	margin-top: -7px;
}

.awesome-pdf-viewer-tooltip > .tooltiptext.visible {
	line-height: 1.35 !important;
}

.shepherd-element-left-adjustment {
	margin-right: 35px !important;
}

/** CSS for SuiteModal **/
.modal301 .modal-dialog {
	width: 301px;
	max-width: 301px;
}
.modal500 .modal-dialog {
	width: 500px;
	max-width: 500px;
}
.suiteModal .modal-dialog {
	display: flex;
	margin: 1.75rem auto;
}
.suiteModal .modal-dialog .modal-content {
	border-radius: 4.8px !important;
	box-sizing: border-box;
	border: none !important;
	background-color: transparent;
}
.suiteModal .modal-dialog .modal-content .lighttheme {
	background: white;
}
.suiteModal .modal-dialog .modal-content .lighttheme .modal-title {
	color: #212529;
	font-weight: 700;
}
.suiteModal .modal-dialog .modal-content .lighttheme .closeIcon {
	color: rgba(0, 0, 0, 0.5);
}
.suiteModal .modal-dialog .modal-content .darktheme {
	background: #05386b;
}
.suiteModal .modal-dialog .modal-content .darktheme .modal-title {
	color: white;
	font-weight: 400;
}
.suiteModal .modal-dialog .modal-content .darktheme .closeIcon {
	color: #99bde1;
}
.suiteModal .modal-dialog .modal-content .modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #dee2e6;
	line-height: 24px;
	font-size: 20px;
	padding: 9px 1rem;
	border-top-left-radius: 4.8px;
	border-top-right-radius: 4.8px;
}
.suiteModal .modal-dialog .modal-content .modal-header .closeIcon {
	width: 26px;
	line-height: 24px;
	cursor: pointer;
}
.suiteModal .modal-dialog .modal-content .modal-header .closeIcon svg {
	width: 26px;
	height: 26px;
}
.suiteModal .modal-dialog .modal-content .modal-header .modal-title {
	line-height: 24px;
	font-size: 20px;
}

/** CSS for ConsentPopUp **/
.consentPopUpBody {
	background: #fff;
	padding: 1rem 1.2rem 1.5rem 1.2rem;
}
.consentPopUpBody .consentPopUpBodyHeading {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #000;
	margin-bottom: 1.2rem;
}
.consentPopUpBody p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #000;
	margin-bottom: 1.2rem;
}
.consentPopUpBody p span {
	font-weight: 700;
}
.consentPopUpBody .mobileNumberSection {
	position: relative;
}
.consentPopUpBody .mobileNumberSection label {
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	color: #212529;
	margin-bottom: 0.3rem;
}
.consentPopUpBody .mobileNumberSection .phoneInput {
	margin-bottom: 3px;
}
.consentPopUpBody .mobileNumberSection .phoneInput input {
	visibility: hidden;
	height: 31px;
}
.consentPopUpBody .mobileNumberSection .phoneInput .flag-dropdown {
	height: 31px;
	width: 52px;
	border: 1px solid #898d91;
	border-radius: 2px 0px 0px 2px;
}
.consentPopUpBody .mobileNumberSection .phoneInput .flag-dropdown .selected-flag {
	width: 50px;
}
.consentPopUpBody .mobileNumberSection .phoneInput .flag-dropdown .arrow {
	border: solid #212529;
	border-width: 0 1px 1px 0;
	height: 6px;
	margin-left: 6px;
	margin-top: 1px;
	top: 0;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 6px;
}
.consentPopUpBody .mobileNumberSection .mobileNumberError .flag-dropdown {
	border: 1px solid #cc4a43;
}
.consentPopUpBody .mobileNumberSection .mobileNumberInput {
	font-family: sans-serif, 'Poppins';
	height: 31px;
	width: 171px;
	border: 1px solid #898d91;
	border-radius: 0px 2px 2px 0px;
	color: #212529;
	font-size: 14px;
	font-weight: 400;
	padding: 4px 8px;
	position: absolute;
	left: 51px;
	top: 31px;
	outline: none;
}
.consentPopUpBody .mobileNumberSection .mobileNumberInputBorder {
	border-top: 1px solid #cc4a43 !important;
	border-right: 1px solid #cc4a43 !important;
	border-bottom: 1px solid #cc4a43 !important;
}

.consentPopUpBody .mobileNumberSection .mobileNumberErrorMessage {
	color: #cc4a43;
	font-size: 14px;
}

.consentPopUpFooter {
	background: #fff;
	padding: 0.75rem 0.25rem;
}
.consentPopUpFooter > div {
	display: flex;
	justify-content: space-between;
}
.consentPopUpFooter button {
	width: 139px;
	height: 48px;
	padding: 6px 12px 6px 12px;
	border-radius: 2px;
	border: none;
	font-size: 20px;
	font-weight: 300;
	line-height: 30px;
	color: #fff;
	cursor: pointer;
}
.consentPopUpFooter .noButton {
	background: #cc4a43;
}
.consentPopUpFooter .yesButton {
	background: #669440;
}
.consentPopUpFooter .disabledYesButton {
	background: #669440;
	opacity: 0.5;
	cursor: not-allowed;
}
.session-page-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
}

.session-page-content {
	text-align: center;
}

.session-page-icon {
	margin-bottom: 10px;
}

.session-page-button {
	width: 200px;
	padding: 6px 20px;
	margin: 20px auto;
}

.custom-btn {
	background-color: #0973ba !important;
	border: 1px solid #0973ba !important;
	color: white !important;
	border-radius: 4px !important;
	width: 100%;
	height: 100%;
	padding: 6px 12px;
}

.custom-btn:hover {
	background-color: #0973ba !important;
	border-color: #0973ba !important;
	color: white !important;
}

.sessionexpiration {
	font-weight: 500;
	font-size: 20px;
	line-height: 24.6px;
}
